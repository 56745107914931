<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard>
        <CCardBody>
          <h3>Edit Role id: {{ $route.params.id }}</h3>
          <CAlert :show.sync="dismissCountDown" color="primary" fade> ({{ dismissCountDown }}) {{ message }} </CAlert>
          <CInput label="Name" type="text" placeholder="Name" v-model="role.name" />
          <CButton color="primary" @click="update()">Save</CButton>
          <CButton color="primary" @click="goBack">Back</CButton>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
//import axios from 'axios'
export default {
  name: 'EditRole',
  /*
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  */
  data: () => {
    return {
      role: {
        id: null,
        name: ''
      },
      message: '',
      dismissSecs: 7,
      dismissCountDown: 0
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    update() {
      let self = this
      //axios.post(  this.$apiAdress + 'roles/' + self.$route.params.id + '?token=' + localStorage.getItem("api_token"),
      this.$http
        .put('admin/roles', { id: self.$route.params.id, name: self.role.name })
        .then(function(response) {
          self.message = 'Successfully updated role.'
          self.showAlert()
        })
        .catch(function(error) {
          if (error.response.data.message == 'The given data was invalid.') {
            self.message = ''
            for (let key in error.response.data.errors) {
              if (error.response.data.errors.hasOwnProperty(key)) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
            self.showAlert()
          } else {
            console.error(error)
            self.$router.push({ path: '/login' })
          }
        })
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted: function() {
    let self = this
    //axios.get(  this.$apiAdress + 'roles/' + self.$route.params.id + '/edit?token=' + localStorage.getItem("api_token"))
    this.$http
      .get(`admin/roles/${self.$route.params.id}/edit`)
      .then(function(response) {
        self.role = response.data
      })
      .catch(function(error) {
        console.error(error)
        self.$router.push({ path: '/login' })
      })
  }
}

/*
      items: (id) => {
        const user = usersData.find( user => user.id.toString() === id)
        const userDetails = user ? Object.entries(user) : [['id', 'Not found']]
        return userDetails.map(([key, value]) => {return {key: key, value: value}})
      },
*/
</script>
